<template>
  <div class="flex_column i18nManage">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="6dc57c13-af84-4ba1-a56c-3cb4b77788d0"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-i18n-dialog
      ref="addI18nDialog"
      @confirm="searchData"
    ></add-i18n-dialog>
  </div>
</template>

<script>
  import i18nService from '@/api/service/i18n';
  import addI18nDialog from './addI18nDialog.vue';

  export default {
    name: 'i18nManage',
    components: { addI18nDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '代码',
            key: 'i18nCode'
          },
          {
            type: 'input',
            label: '中文',
            key: 'cnName'
          },
          {
            type: 'input',
            label: '英文',
            key: 'enName'
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editI18n',
                    callback: () => this.$refs.addI18nDialog.openFrame(row.id)
                  }
                ]}></permission-element>
            )
          },
          { prop: 'i18nCode', label: '代码', width: 100 },
          { prop: 'cnName', label: '中文', width: 120 },
          { prop: 'enName', label: '英文', width: 120 },
          { prop: 'remark', label: '备注', width: 120 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addI18n',
            callback: () => this.$refs.addI18nDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteI18ns',
            callback: this.deleteI18ns
          }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return i18nService.getI18nList(params);
      },
      deleteI18ns() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          i18nService
            .deleteI18ns({ ids: this.selectedData.map((item) => item.id) })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
