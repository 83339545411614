import baseService from '../baseService';
const request = baseService('i18n');

export default {
  getI18nList(params) {
    return request('/getI18nList', params, 'get');
  },
  deleteI18ns(params) {
    return request('/deleteI18ns', params, 'delete');
  },
  getI18nDetail(params) {
    return request('/getI18nDetail', params, 'get');
  },
  addI18n(params) {
    return request('/addI18n', params, 'post');
  },
  editI18n(params) {
    return request('/editI18n', params, 'put');
  }
};
