<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑多语言' : '新增多语言'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import i18nService from '@/api/service/i18n.js';

  export default {
    name: 'addI18nDialog',
    data() {
      return {
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '代码',
              prop: 'i18nCode',
              rules: [this.$formRules.required('请输入代码')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '中文',
              prop: 'cnName'
              // rules: [this.$formRules.required('请输入中文')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '英文',
              prop: 'enName'
              // rules: [this.$formRules.required('请输入英文')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '备注',
              prop: 'remark',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          i18nService.getI18nDetail({ id }).then((res) => {
            this.formData = res;
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return i18nService[this.isEdit ? 'editI18n' : 'addI18n'](
              this.formData
            );
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.isEdit = false;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
